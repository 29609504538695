<template>
  <va-card title="Request">
    <div style="margin-bottom: 15px;">
      <va-button
        @click="sendAgain()"
        small
        color="primary"
        class="ma-0"
      >
        Send again
      </va-button>
    </div>
    <div class="mb-3">
      <h2 class="display-4 mb-1">Status</h2>
      <span>{{ statusName }}</span>
    </div>

    <div class="mb-3">
      <h2 class="display-4 mb-1">Subject</h2>
      <span>{{ subject }}</span>
    </div>

    <div class="mb-3">
      <h2 class="display-4 mb-1">Letter body</h2>
      <span v-html="letterBody"></span>
    </div>

    <div class="mb-3">
      <h2 class="display-4 mb-1">Date Create</h2>
      <span>{{ dateCreate }}</span>
    </div>

    <div v-if="dateUpdate" class="mb-3">
      <h2 class="display-4 mb-1">Date Update</h2>
      <span>{{ dateUpdate }}</span>
    </div>

    <div class="mb-3" >
      <h2 class="display-4 mb-1">From</h2>
      <span>{{ recipients.from }}</span>
    </div>

    <div class="mb-3" >
      <h2 class="display-4 mb-1">To</h2>
      <span v-for="(rec, index) in recipients.to" :key="index">{{ rec }}</span>
    </div>

    <div v-if="attachments" class="mb-3">
      <h2 class="display-4 mb-1">Attachments</h2>
      <span>{{ attachments }}</span>
    </div>
  </va-card>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  data () {
    return {
      statusName: '',
      subject: '',
      letterBody: '',
      dateCreate: '',
      dateUpdate: '',
      recipients: '',
      attachments: '',
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    locale () {
      return Vue.i18n.locale()
    },
    apiUrl () {
      return process.env.VUE_APP_AUTOSTRADA_API_URL
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    sendAgain (id = this.$attrs.id) {
      axios.get(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/letter/resend/${id}`)
        .then(response => {
          this.$router.push({ name: 'autostrada-letters' })
          this.showToast('Success')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/letter/${this.$attrs.id}`)
        .then(response => {
          this.statusName = response.data.status_name
          this.subject = response.data.subject
          this.letterBody = response.data.body
          const date = new Date(response.data.date_create * 1000)
          this.dateCreate = date.toLocaleDateString('uk-UA') + ' ' + date.toLocaleTimeString('uk-UA')
          if (response.data.date_update) {
            const date2 = new Date(response.data.date_update * 1000)
            this.dateUpdate = date2.toLocaleDateString('uk-UA') + ' ' + date2.toLocaleTimeString('uk-UA')
          }

          this.recipients = JSON.parse(response.data.recipients)
          this.attachments = response.data.attachments
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
</style>
